
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/** 
- put all utility classes inside @layer utilities */
@layer utilities {
  /**
  below class should be used instead of flex flex-col, it will 
convert individual items inside rows to separate rows
 automatically on small screens */
  .flex-row-responsive {
    @apply  flex-col w-full md:flex-row;
  }

  .flex-row-reverse-responsive {
    @apply  flex-col-reverse w-full md:flex-row;
  }
}
